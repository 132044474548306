// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home'; // Asegúrate de que el componente Home exista
import Login from './components/login';
import Admin from './components/admin';
import VentaOW from './components/ventaow';
import Cuentas from './components/cuentas';
import CuentaDetalle from './components/cuentadetalle';
import Register from './components/register';
import MiCuenta from './components/mi-cuenta';
import Carrito from './components/carrito';
import ProtectedRoute from './components/ProtectedRoute';
import Faq from './components/faq';
import Mensajes from './components/mensajes';
import Rivals from './components/rivals';
import Genshin from './components/genshin';
import Deadlock from './components/deadlock';
import Fortnite from './components/fortnite';
import Overwatch from './components/overwatch';

function App() {
    return (
        <Router>
            <div>
                <Routes>
                    {/* Ruta de inicio, permitida solo para usuarios no logueados o de tipo 2 */}
                    <Route 
                        path="/" 
                        element={
                            <ProtectedRoute permitirNoLogueados={true} excluirTipo1={true}>
                                <Home />
                            </ProtectedRoute>
                        } 
                    />

                    {/* Rutas públicas */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />

                    {/* Rutas protegidas para solo no logueados y tipo 2 */}
                    <Route 
                        path="/cuentas" 
                        element={
                            <ProtectedRoute permitirNoLogueados={true} excluirTipo1={true}>
                                <Cuentas />
                            </ProtectedRoute>
                        } 
                    />
                    {/* Nueva ruta para FAQ */}
                    <Route 
                        path="/faq" 
                        element={
                            <ProtectedRoute permitirNoLogueados={true} excluirTipo1={true}>
                                <Faq />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/card/:id" 
                        element={
                            <ProtectedRoute permitirNoLogueados={true} excluirTipo1={true}>
                                <CuentaDetalle />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/rivals" 
                        element={
                            <ProtectedRoute permitirNoLogueados={true} excluirTipo1={true}>
                                <Rivals />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/genshin" 
                        element={
                            <ProtectedRoute permitirNoLogueados={true} excluirTipo1={true}>
                                <Genshin />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/deadlock" 
                        element={
                            <ProtectedRoute permitirNoLogueados={true} excluirTipo1={true}>
                                <Deadlock />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/fortnite" 
                        element={
                            <ProtectedRoute permitirNoLogueados={true} excluirTipo1={true}>
                                <Fortnite />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/overwatch" 
                        element={
                            <ProtectedRoute permitirNoLogueados={true} excluirTipo1={true}>
                                <Overwatch />
                            </ProtectedRoute>
                        } 
                    />

                    {/* Rutas protegidas para tipo 1 */}
                    <Route 
                        path="/admin" 
                        element={
                            <ProtectedRoute tipoRequerido="1">
                                <Admin />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/admin-venta" 
                        element={
                            <ProtectedRoute tipoRequerido="1">
                                <VentaOW />
                            </ProtectedRoute>
                        } 
                    />

                    {/* Ruta para cualquier usuario con sesión iniciada */}
                    <Route 
                        path="/mi-cuenta" 
                        element={
                            <ProtectedRoute>
                                <MiCuenta />
                            </ProtectedRoute>
                        } 
                    />

                    {/* Ruta para cualquier usuario con sesión iniciada */}
                    <Route 
                        path="/mensajes" 
                        element={
                            <ProtectedRoute>
                                <Mensajes />
                            </ProtectedRoute>
                        } 
                    />

                    {/* Solo para tipo 2 */}
                    <Route 
                        path="/carrito" 
                        element={
                            <ProtectedRoute tipoRequerido="2">
                                <Carrito />
                            </ProtectedRoute>
                        } 
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
