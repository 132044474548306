// src/components/Faq.js
import React, { useState } from 'react';
import Navbar from './navbar';
import Footer from './footer';
import '../styles/faq.css';

const faqData = [
    { question: 'What is rank boosting?', answer: 'Rank boosting is  a specialized service for players who want to quickly and effectively improve their ranking in Overwatch. Our team of professional players is dedicated to elevating your rank, allowing you to enjoy better matches, rewards, and a more fulfilling gaming experience.' },
    { question: 'Is rank boosting safe?', answer: 'Yes, we use safe methods and protect your account throughout the process. Our team adheres to game policies to avoid any risks.' },
    { question: 'How long will the boosting take?', answer: 'The time varies depending on your current rank and the goal you want to achieve. However, our team strives to complete the service as quickly as possible.' },
    { question: 'Will I have access to my account during the boosting?', answer: 'We recommend not accessing your account while the service is being performed to avoid any issues. We will notify you as soon as the boosting is complete.' },
    { question: 'Which games offer rank boosting?', answer: 'We offer rank boosting for several competitive games, including Overwatch, League of Legends, Valorant, and more. Please check our store for the full list.' },
    { question: "What if I'm not satisfied with the result?", answer: "We strive to provide results that meet your expectations. If you're not satisfied, please contact us, and we will do our best to resolve the issue." },
    { question: 'Can I choose the rank I want to achieve?', answer: 'Yes, you can choose the heroes that will be played for the boost.' },
    { question: 'Do you offer refunds?', answer: 'Our refund policy depends on specific circumstances. Please check our terms and conditions for more details.' }
];

function Faq() {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleAnswer = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <div className="main-container">
            <Navbar />
            <div style={{ padding: '20px' }} className='faq'>
                <h1>Frequently Asked Questions</h1>
                {faqData.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <div 
                            className="faq-question" 
                            onClick={() => toggleAnswer(index)}
                        >
                            {faq.question}
                            <span className={`faq-arrow ${expandedIndex === index ? 'expanded' : ''}`}>
                                ▼
                            </span>
                        </div>
                        <div className={`faq-answer ${expandedIndex === index ? 'active' : ''}`}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
}

export default Faq;
