/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Navbar from './navbar';
import Footer from './footer';
import '../styles/mensajes.css';
import logo from '../img/logo.png'; // Importa el logo

const Mensajes = () => {
    const [messages, setMessages] = useState([]); // Mensajes de la conversación seleccionada
    const [newMessage, setNewMessage] = useState('');
    const chatContainerRef = useRef(null);
    const messagesEndRef = useRef(null);  // Para el scroll automático hacia el final
    const userType = localStorage.getItem('userType'); // Obtiene el tipo de usuario desde localStorage
    const userId = parseInt(localStorage.getItem('userId'), 10); // Convierte el ID a   número
    const [conversations, setConversations] = useState([]); // Lista de conversaciones
    const [selectedConversation, setSelectedConversation] = useState(null); // Conversación seleccionada
    const [searchQuery, setSearchQuery] = useState(''); // for search query
    const [filteredUsers, setFilteredUsers] = useState([]); // Initialize as empty array

    useEffect(() => {
        if (userType === '2' && userId) {
            fetchMessages(userId);
        } else if (userType === '1' && userId) {
            fetchConversations();
        }
    }, [userType, userId]);

    useEffect(() => {
        if (userType === '1' && searchQuery.trim()) {
            searchUsers(searchQuery);
        }
    }, [searchQuery])

    const searchUsers = async (query) => {
        console.log(`Searching for: ${query}`);  // Mensaje de depuración
        try {
            const response = await fetch(`https://alienboost.onrender.com/clientes?username=${query}`);
            if (response.ok) {
                const data = await response.json();
                console.log("Received data:", data);  // Verifica lo que devuelve el backend
                const filtered = data.clientes.filter(user =>
                    user.username.toLowerCase().includes(query.toLowerCase()) // Verifica la coincidencia
                );
                console.log("Filtered users:", filtered);  // Verifica los usuarios filtrados
                setFilteredUsers(filtered); 
            } else {
                console.error("Error fetching users:", response.status);
            }
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };
    
    
    
    
    
    
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        console.log("Search Query:", query);  // Verifica el valor de la consulta
    };
    

    const handleUserClick = async (userId) => {
        try {
            // Hacer una llamada a la API de FastAPI para verificar/crear la conversación
            const response = await fetch(`https://alienboost.onrender.com/crear_conversacion/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }) // Enviar el userId en el cuerpo
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
    
            if (data.message) {
                console.log(data.message); // Muestra un mensaje según el resultado
            }
    
            // Actualizar el estado con el ID de la conversación
            handleConversationClick(data.conversation_id); // Aquí se actualiza el estado con el conversation_id
    
            // Actualizar las conversaciones (llamar a la función fetchConversations)
            fetchConversations(); // Esto actualizará la lista de conversaciones con la nueva conversación
        
        } catch (error) {
            console.error("Error al crear o verificar la conversación:", error);
            alert("Hubo un error al crear o verificar la conversación.");
        }
    };
    
    
    
    
    
    
    useEffect(() => {
        // Desplaza al final automáticamente cuando los mensajes cambian
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);
    
    const fetchConversations = async () => {
        try {
            const response = await fetch('https://alienboost.onrender.com/obtener-todas-conversaciones');
            if (response.ok) {
                const data = await response.json();
                console.log("Datos de las conversaciones:", data);
                setConversations(data.conversaciones);
                if (data.conversaciones.length > 0) {
                    const firstConversation = data.conversaciones[0];
                    if (firstConversation.mensajes && JSON.stringify(firstConversation.mensajes) !== JSON.stringify(messages)) {
                        setMessages(firstConversation.mensajes); // Solo carga los mensajes si no están ya cargados
                        setSelectedConversation(firstConversation.conversacion_id);
                    }
                }
            } else {
                const errorData = await response.text();
                console.error(`Error al obtener las conversaciones: ${response.status} - ${errorData}`);
            }
        } catch (error) {
            console.error("Error en la solicitud de obtener conversaciones:", error);
        }
    };
    
    
    const fetchMessages = async (userId) => {
        try {
            const response = await fetch(`https://alienboost.onrender.com/obtener-conversacion/${userId}`);
            if (response.ok) {
                const data = await response.json();
                setMessages(data.mensajes);
            } else {
                const errorData = await response.text();
                console.error(`Error al obtener los mensajes: ${response.status} - ${errorData}`);
            }
        } catch (error) {
            console.error("Error en la solicitud de obtener los mensajes:", error);
        }
    };
    
    

    const handleConversationClick = async (conversationId) => {
        setSelectedConversation(conversationId);
        
        // Encuentra la conversación seleccionada en la lista de conversaciones
        const selectedConv = conversations.find((conv) => conv.conversacion_id === conversationId);
        
        // Actualiza los mensajes con los de la conversación seleccionada, o usa un array vacío si no hay mensajes
        setMessages(selectedConv?.mensajes || []);
        
        // Obtén el último mensaje de la conversación seleccionada
        const lastMessage = selectedConv?.mensajes[selectedConv.mensajes.length - 1];
    
        // Verifica si la notificación está activada y si el remitente no es el usuario logueado
        const username = localStorage.getItem('username'); // Obtén el nombre de usuario logueado
        
        if (lastMessage && lastMessage.notificacion === 1 && lastMessage.remitente !== username) {
            try {
                // Realiza la solicitud PUT para actualizar la notificación a 2
                const response = await fetch(`https://alienboost.onrender.com/actualizar-notificacion/${lastMessage.mensaje_id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
    
                if (response.ok) {
                    console.log("Notificación actualizada correctamente.");
                    fetchConversations()
                    // Si es necesario, actualiza el estado de las conversaciones o mensajes
                    // Podrías actualizar la conversación aquí si lo deseas, o recargar las conversaciones
                } else {
                    console.error("Error al actualizar la notificación.");
                }
            } catch (error) {
                console.error("Error en la solicitud PUT:", error);
            }
        }
    };
    

    
    
    

    const handleMessageChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            if (userType === '1' && selectedConversation) {
                sendMessageAdmin(newMessage, selectedConversation);
            } else if (userType === '2' && userId) {
                sendMessage(userId, newMessage);
            }
            setNewMessage('');
        }
    };
    

    const sendMessage = async (loggedInUserId, content) => {
        const usuarioLogueadoId = parseInt(loggedInUserId, 10);
    
        try {
            const response = await fetch('https://alienboost.onrender.com/enviar-mensaje', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    contenido: content,
                    usuario_logueado_id: usuarioLogueadoId,
                }),
            });
    
            if (response.ok) {
                // Añadir el mensaje recién enviado al estado de los mensajes
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        remitente: 'user',
                        contenido: content,
                        fecha_envio: new Date().toLocaleString(),
                    },
                ]);
    
            } else {
                console.error("Error en la solicitud de enviar mensaje:", response.status);
            }
        } catch (error) {
            console.error("Error en la solicitud de enviar mensaje:", error);
        }
    };

    const sendMessageAdmin = async (content, conversationId) => {
        try {
            const response = await fetch('https://alienboost.onrender.com/enviar-mensaje-admin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    contenido: content,
                    conversacion_id: conversationId,
                }),
            });

            if (response.ok) {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        remitente: 'admin',
                        contenido: content,
                        fecha_envio: new Date().toLocaleString(),
                    },
                ]);

                fetchConversations();
            } else {
                console.error("Error en la solicitud de enviar mensaje del admin:", response.status);
            }
        } catch (error) {
            console.error("Error en la solicitud de enviar mensaje del admin:", error);
        }
    };

    

    return (
        <div className="main-container">
            <Navbar />
            <div className={userType === '2' ? "chat-container" : "chat-container2"}>
                            {/* Search bar for admin to find users */}
                {/* Contenedor principal para la barra de búsqueda y los resultados */}
                {userType === '1' && (
                    <div className="search-container">
                        {/* Barra de búsqueda */}
                    {/* Barra de búsqueda estilizada */}
                    <div className="search-bar">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search users by username..."
                        />
                        <i className="search-icon fa fa-search"></i> {/* Icono de búsqueda */}
                    </div>


                        {/* Resultados de búsqueda */}
                        {searchQuery && (
                            <div className="search-results">
                                {filteredUsers.length === 0 ? (
                                    <p>No users found.</p>
                                ) : (
                                    filteredUsers.map(user => (
                                        <div
                                            key={user.id}
                                            className="user-item"
                                            onClick={() => handleUserClick(user.id)}
                                        >
                                            {user.username} {/* Mostrar el username */}
                                        </div>
                                    ))
                                )}
                            </div>
                        )}
                    </div>
                )}
                {userType === '2' ? (
                    <div className="chat-header">
                        <img className="chat-avatar" src={logo} alt="Admin" />
                        <div className="chat-username">Admin</div>
                    </div>
                ) : (
<div className="conversations-list">
    <h3>Conversations</h3>
    <div className="conversations-container">
        {conversations.length === 0 ? (
            <p>No conversations available.</p>
        ) : (
            conversations.map((conversation) => {
                // Verificar si los mensajes están disponibles
                if (conversation.mensajes && conversation.mensajes.length > 0) {
                    // Obtener el último mensaje de la conversación
                    const lastMessage = conversation.mensajes[conversation.mensajes.length - 1];

                    // Obtener el nombre de usuario desde localStorage
                    const username = localStorage.getItem('userUsername');

                    // Verificar que el nombre del remitente sea diferente al usuario logueado y que la notificación esté activada
                    const showNotification = lastMessage.notificacion === 1 && lastMessage.remitente !== username;

                    // Imprimir remitente y username en la consola
                    console.log("Remitente:", lastMessage.remitente);
                    console.log("Username logueado:", username);
                    console.log("Mostrar notificación:", showNotification);

                    return (
                        <div
                            key={conversation.conversacion_id}
                            className={`conversation-item ${
                                selectedConversation === conversation.conversacion_id ? 'selected' : ''
                            }`}
                            onClick={() => handleConversationClick(conversation.conversacion_id)}
                        >
                            {/* Mostrar el nombre del otro usuario en la conversación */}
                            {username === conversation.usuario_1
                                ? `${conversation.usuario_2}`
                                : `${conversation.usuario_1}`}

                            {/* Mostrar el círculo rojo solo si las condiciones se cumplen */}
                            {showNotification && (
                                <span className="notification-indicator"></span>
                            )}
                        </div>
                    );
                } else {
                    console.warn("No messages found for conversation:", conversation.conversacion_id);
                    return null;  // Si no hay mensajes, no se muestra la conversación
                }
            })
        )}
    </div>
</div>






                )}

                <div className="chat-body-container">
                    <div className="chat-body" ref={chatContainerRef}>
                        {messages.length === 0 ? (
                            <div className="no-messages">No messages yet. Start the conversation!</div>
                        ) : (
                        <div>
                            <div className="chat-body" ref={chatContainerRef}>
                                {messages.length === 0 ? (
                                    <div className="no-messages">No messages yet. Start the conversation!</div>
                                ) : (
                                    // Aquí añadimos una verificación para el tipo de usuario
                                    userType === '1' ? (
                                        messages.map((msg, index) => (
                                            <div
                                                key={index}
                                                className={`chat-message ${msg.remitente === 'admin' ? 'user-message' : 'admin-message'}`}
                                            >
                                                <div className="chat-message-content">{msg.contenido}</div>
                                                <div className="chat-message-timestamp">{new Date(msg.fecha_envio).toLocaleString()}</div>
                                            </div>
                                        ))
                                    ) : (
                                        messages.map((msg, index) => (
                                            <div
                                                key={index}
                                                className={`chat-message ${msg.remitente === 'admin' ? 'admin-message' : 'user-message'}`}
                                            >
                                                <div className="chat-message-content">{msg.contenido}</div>
                                                <div className="chat-message-timestamp">{new Date(msg.fecha_envio).toLocaleString()}</div>
                                            </div>
                                        ))
                                    )
                                )}
                                <div ref={messagesEndRef} />
                            </div>
                        </div>

                              
                        )}
                        <div ref={messagesEndRef} />
                    </div>

                    <div className="chat-input">
                        <input
                            type="text"
                            value={newMessage}
                            onChange={handleMessageChange}
                            placeholder="Write a message..."
                        />
                        <button onClick={handleSendMessage}>Send</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Mensajes;
