import React from 'react';
import Footer from './footer'; // Importa el Footer
import Navbar from './navbar'; // Importa el Navbar
import '../styles/deadlock.css';

const Deadlock = () => {
    return (
        <div className="main-container">
            <Navbar />
            {/* Aquí puedes agregar el contenido principal de Rivals */}
            <div className='contenedor-deadlock'>
                <h1>Deadlock</h1>
                <p>Coming Soon</p>
            </div>

            <Footer />
        </div>
    );
};

export default Deadlock;
