import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link para navegación
import Navbar from './navbar';
import '../styles/login.css';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://alienboost.onrender.com/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ correo: email, contrasena: password }),
            });

            if (!response.ok) {
                throw new Error('Correo o contraseña incorrectos');
            }

            const data = await response.json();
            console.log(data);
            const { id, tipo, nombre, username } = data.user;

            localStorage.setItem('userId', id);
            localStorage.setItem('userType', tipo);
            localStorage.setItem('userName', nombre);
            localStorage.setItem('userUsername', username);
            
            if (tipo === 2) {
                navigate('/');
            } else if (tipo === 1) {
                navigate('/admin');
            }

        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <>
            <Navbar />
            <div className="login-container">
                <h2 className="login-title">Log In</h2>
                <form onSubmit={handleSubmit} className="login-form">
                    <label htmlFor="email" className="login-label">Email</label>
                    <input
                        type="email"
                        id="email"
                        className="login-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    
                    <label htmlFor="password" className="login-label">Password</label>
                    <input
                        type="password"
                        id="password"
                        className="login-input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />

                    {error && <p className="error-message">{error}</p>}

                    <button type="submit" className="login-button">Log In</button>
                </form>
                <p className="signup-link">
                    Don't have an account? <Link to="/register">Sign up</Link>
                </p>
            </div>
        </>
    );
}

export default Login;
