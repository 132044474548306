// Footer.jsx
import React from 'react';
import '../styles/footer.css'; // Asegúrate de importar el CSS

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-links">
                    <a href="/cuentas" className="footer-link">Accounts</a>
                    <a href="#about-us" className="footer-link">About Us</a>
                    <a href="/faq" className="footer-link">FAQ</a>
                </div>
                <div className="footer-contact">
                    <p>alienboost@gmail.com</p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Alien Boost. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
