import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './navbar';
import Footer from './footer';
import profile1 from '../img/profile1.jpg';
import profile2 from '../img/profile2.jpg';
import profile3 from '../img/profile3.jpg';
import profile4 from '../img/profile4.jpg';
import profile5 from '../img/profile5.jpg';
import profile6 from '../img/profile6.jpg';
import '../styles/mi-cuenta.css';

function MiCuenta() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    useEffect(() => {
        const images = [profile1, profile2, profile3, profile4, profile5, profile6];
        const randomImage = images[Math.floor(Math.random() * images.length)];
        setProfileImage(randomImage);

        const fetchUserData = async () => {
            try {
                const userId = localStorage.getItem('userId');
                if (!userId) throw new Error('User ID not found in local storage.');

                const response = await fetch(`https://alienboost.onrender.com/users/${userId}`);
                if (!response.ok) throw new Error('Failed to fetch account data.');

                const data = await response.json();
                setUserData(data);
                setEmail(data.correo);
                setUsername(data.username);
                setFirstName(data.nombre);
                setLastName(data.apellido);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleEditClick = () => setIsEditing(true);

    const handleAcceptClick = async () => {
        try {
            const updatedUser = {
                correo: email,
                username: username,
                nombre: firstName,
                apellido: lastName,
            };
    
            const response = await fetch(`https://alienboost.onrender.com/editar-usuario/${userData.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedUser),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                // Set the error message from the response
                setError(errorData.detail || 'Error updating user');
                throw new Error(errorData.detail || 'Error updating user');
            }
    
            const result = await response.json();
            console.log(result.message);
    
            // Update state with new values
            setUserData((prevData) => ({
                ...prevData,
                correo: updatedUser.correo,
                username: updatedUser.username,
                nombre: updatedUser.nombre,
                apellido: updatedUser.apellido,
            }));
    
            // Update individual states
            setEmail(updatedUser.correo);
            setUsername(updatedUser.username);
            setFirstName(updatedUser.nombre);
            setLastName(updatedUser.apellido);
    
            setIsEditing(false);
            setError(''); // Clear the error after successful update
        } catch (error) {
            console.error('Error updating account:', error);
            // Set a user-friendly error message if needed
            setError('Error al actualizar la cuenta. Por favor, inténtalo de nuevo.');
        }
    };
    
    
    
    
    
    

    const handleCancelClick = () => {
        setIsEditing(false);
        // Optionally reset the state to the original user data
        if (userData) {
            setEmail(userData.correo);
            setUsername(userData.username);
            setFirstName(userData.nombre);
            setLastName(userData.apellido);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'correo':
                setEmail(value);
                break;
            case 'username':
                setUsername(value);
                break;
            case 'nombre':
                setFirstName(value);
                break;
            case 'apellido':
                setLastName(value);
                break;
            default:
                break;
        }
    };
    

    const handleLogout = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('userType');
        localStorage.removeItem('userName');
        localStorage.removeItem('userUsername');
        navigate('/'); 
    };

    const handleDeleteClick = async () => {
        if (window.confirm("Are you sure you want to delete this account?")) {
            try {
                const userId = localStorage.getItem('userId');
                const response = await fetch(`https://alienboost.onrender.com/users/${userId}`, {
                    method: 'DELETE',
                });

                if (!response.ok) throw new Error('Failed to delete account.');

                alert("Account deleted successfully. You have been logged out.");
                handleLogout(); 
            } catch (err) {
                alert("Error deleting account: " + err.message);
            }
        }
    };

    return (
        <div className="main-container">
            <Navbar />
            <div className="mi-cuenta-container">
                <h2 className="mi-cuenta-title">My Account</h2>
                {loading && <p>Loading account information...</p>}
                {error && <p className="error-message">{error}</p>}
                {userData && !loading && (
                    <div className="account-info">
                        <img src={profileImage} alt="Profile" className="profile-image" />
                        
                        <p>
                            <strong>Email: </strong>
                            {isEditing ? (
                                <input type="text" name="correo" value={email} onChange={handleInputChange} />
                            ) : (
                                <span>{userData.correo}</span>
                            )}
                        </p>
                        <p>
                            <strong>Username: </strong>
                            {isEditing ? (
                                <input type="text" name="username" value={username} onChange={handleInputChange} />
                            ) : (
                                <span>{userData.username}</span>
                            )}
                        </p>
                        <p>
                            <strong>First Name: </strong>
                            {isEditing ? (
                                <input type="text" name="nombre" value={firstName} onChange={handleInputChange} />
                            ) : (
                                <span>{userData.nombre}</span>
                            )}
                        </p>
                        <p>
                            <strong>Last Name: </strong>
                            {isEditing ? (
                                <input type="text" name="apellido" value={lastName} onChange={handleInputChange} />
                            ) : (
                                <span>{userData.apellido}</span>
                            )}
                        </p>
    
                        {!isEditing ? (
                            <button className="mi-cuenta-edit-button" onClick={handleEditClick}>Edit Account</button>
                        ) : (
                            <>
                                <button className="mi-cuenta-accept-button" onClick={handleAcceptClick}>Accept</button>
                                <button className="mi-cuenta-cancel-button" onClick={handleCancelClick}>Cancel</button>
                                <button className="mi-cuenta-delete-button" onClick={handleDeleteClick}>Delete Account</button>
                            </>
                        )}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
    
}

export default MiCuenta;