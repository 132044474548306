import React from 'react';
import Footer from './footer'; // Importa el Footer
import Navbar from './navbar'; // Importa el Navbar
import '../styles/rivals.css';

const Rivals = () => {
    return (
        <div className="main-container">
            <Navbar />
            {/* Aquí puedes agregar el contenido principal de Rivals */}
            <div className='contenedor-rivals'>
                <h1>Marvel Rivals</h1>
                <p>Coming Soon</p>
            </div>

            <Footer />
        </div>
    );
};

export default Rivals;
