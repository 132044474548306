// src/components/Admin.js
import React from 'react';
import Navbar from './navbar'; // Asegúrate de que la ruta de importación sea correcta
import '../styles/admin.css'; // Si tienes estilos específicos para Admin

function Admin() {
    return (
        <>
            <Navbar />
            <div className="admin-container">
                <h2 className="admin-welcome">Bienvenido, Admin</h2>
                {/* Aquí puedes agregar más contenido o componentes relacionados con la administración */}
            </div>
        </>
    );
}

export default Admin;
