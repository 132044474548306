import React, { useEffect, useState } from 'react';
import Navbar from './navbar'; // Importa el componente Navbar
import Footer from './footer'; // Importa el Footer
import '../styles/carrito.css';
import overwatch from '../img/overwatch.png';
import fortnite from '../img/fortnite.png';
import deadlock from '../img/deadlock.png';
import genshin from '../img/genshin.png';
import rivals from '../img/rivals.png';

const Carrito = () => {
    const [carritos, setCarritos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false); // Estado para manejar si el modal está abierto
    const [modalContent, setModalContent] = useState(null); // Estado para almacenar el contenido del modal
    const [venta, setVenta] = useState(null); // Estado para almacenar los detalles de la venta
    const [boost, setBoost] = useState(null); // Estado para almacenar los detalles de la venta

    // Recuperar el userId del localStorage
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        // Verificamos si el userId está presente
        if (userId) {
            // Realizamos la solicitud al backend
            fetch(`https://alienboost.onrender.com/existe-carrito/${userId}`)
                .then((response) => response.json())
                .then((data) => {
                    setCarritos(data); // Guardamos los carritos en el estado
                    setLoading(false);  // Cambiamos el estado de loading a false
                })
                .catch((error) => {
                    setError(error.message); // Guardamos cualquier error
                    setLoading(false);
                });
        } else {
            setError('Usuario no encontrado en el localStorage');
            setLoading(false);
        }
    }, [userId]); // Dependemos de userId, se ejecutará cada vez que cambie.

    // Si estamos cargando o hay un error, mostramos un mensaje correspondiente
    if (loading) {
        return <div className="carrito-loading">Cargando...</div>;
    }

    if (error) {
        return <div className="carrito-error">Error: {error}</div>;
    }

    // Función para obtener la imagen correspondiente al juego
    const getGameImage = (gameName) => {
        if (gameName) {
            const game = gameName.toLowerCase();
            if (game.includes("overwatch")) return overwatch;
            if (game.includes("fortnite")) return fortnite;
            if (game.includes("deadlock")) return deadlock;
            if (game.includes("genshin")) return genshin;
            if (game.includes("rivals")) return rivals;
        }
        return null; // Si no hay juego o no coincide con ningún nombre, no mostrar imagen
    };

    // Función para manejar el clic en "Ver Detalles"
    const handleVerDetalles = (id, tipo) => {
        setModalContent({ id, tipo }); // Establecemos el contenido del modal con el id y tipo
        setModalOpen(true); // Abrimos el modal

        if (tipo === 'Venta') {
            fetchVenta(id); // Si es una venta, obtenemos los detalles
        }
        if (tipo === 'Boost') {
            fetchBoost(id); // Si es un boost, obtenemos los detalles
        }
    };

    // Función para obtener los detalles de la venta
    const fetchVenta = async (id) => {
        try {
            const response = await fetch(`https://alienboost.onrender.com/ventas/${id}`);
            if (!response.ok) {
                throw new Error('Error al obtener los detalles de la venta');
            }
            const data = await response.json();
            setVenta(data); // Guardamos los detalles de la venta en el estado
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchBoost = async (id) => {
        try {
            const response = await fetch(`https://alienboost.onrender.com/boost/${id}`);
            if (!response.ok) {
                throw new Error('Error al obtener los detalles de la venta');
            }
            const data = await response.json();
            setBoost(data); // Guardamos los detalles de la venta en el estado
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Función para cerrar el modal
    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null); // Limpiamos el contenido cuando se cierre el modal
        setVenta(null); // Limpiamos los detalles de la venta
    };

    const handleDelete = async (cartId) => {
        try {
          const response = await fetch(`https://alienboost.onrender.com/borrar-carrito/${cartId}`, {
            method: "DELETE",
          });
    
          if (response.ok) {
            alert("Carrito eliminado exitosamente");
            // Reload the page after successful deletion
            window.location.reload();
          } else {
            alert("Error al eliminar el carrito");
          }
        } catch (error) {
          console.error("Error al realizar la solicitud:", error);
          alert("Hubo un problema al borrar el carrito");
        }
    };
    

    return (
        <div className='main-container'>
            <Navbar />
            <div className="carrito-container">
                <h2 className="carrito-title">Shopping Cart</h2>
                
                {/* Tabla de productos */}
                <table className="carrito-table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Product</th>
                        <th>Description</th>
                        <th>Estimated Time</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Details</th>
                        <th>Remove</th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* Verificamos si hay carritos y los mostramos */}
                    {carritos.length > 0 ? (
                        carritos.map((carrito) => (
                        <tr key={carrito.carrito_id} className="carrito-row">
                            {/* Logo */}
                            <td>
                            <img
                                src={getGameImage(carrito.venta_juego || carrito.boost_juego)} // Usa boost_juego si venta_juego está vacío
                                alt={carrito.venta_juego || carrito.boost_juego}
                                className="game-image"
                            />
                            </td>

                            {/* Producto */}
                            <td>{carrito.venta_juego ? "Account" : "Boost"}</td>

                            {/* Descripción */}
                            <td>{carrito.venta_juego ? carrito.venta_titulo : carrito.boost_descripcion}</td>

                            {/* Tiempo Estimado */}
                            <td>{carrito.venta_juego ? "Immediate" : `${carrito.boost_tiempo === 1 ? `${carrito.boost_tiempo} day` : `${carrito.boost_tiempo} days`}`}</td>

                            {/* Precio */}
                            <td>USD ${carrito.carrito_precio}</td>

                            {/* Cantidad */}
                            <td>1</td>

                            {/* Detalles */}
                            <td>
                            <button 
                                className="ver-detalles-btn"
                                onClick={() => {
                                const id = carrito.venta_juego ? carrito.venta_id : carrito.boost_id;
                                const tipo = carrito.venta_juego ? 'Venta' : 'Boost';
                                handleVerDetalles(id, tipo); // Enviamos el id y tipo al handler
                                }}
                            >
                                View Details
                            </button>
                            </td>

                            {/* Botón con el SVG */}
                            <td>
                            <button className="svg-icon-btn" onClick={() => handleDelete(carrito.carrito_id)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6M14 10V17M10 10V17" stroke="#51cda2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            </td>
                        </tr>
                        ))
                    ) : (
                        <tr>
                        <td colSpan="8" className="carrito-empty">No items available in the cart.</td>
                        </tr>
                    )}
                    </tbody>
                </table>

                {/* Recuadro con el precio total */}
                <div className="total-container">
                    <div className="total-box">
                        {/* Ensure carritos is an array before using reduce */}
                        <p>Total: USD ${Array.isArray(carritos) ? carritos.reduce((total, carrito) => total + carrito.carrito_precio, 0) : 0}</p>
                    </div>
                    {carritos.length > 0 ? (
                    // If carritos has items, render the active button
                    <div className="proceed-btn-container">
                        <button className="proceed-btn">Proceed to Checkout</button>
                    </div>
                    ) : (
                    // If carritos is empty, render the disabled button
                    <div className="proceed-btn-container">
                        <button
                        className="proceed-btn disabled-btn" // Add 'disabled-btn' class to style it as disabled
                        disabled // Disable the button
                        >
                        Proceed to Checkout
                        </button>
                    </div>
                    )}

                </div>

                </div>


            {/* Modal */}
            {modalOpen && (
    <div className="modal-overlay">
        <div className="modal-content">
            {/* Mostrar los detalles si es tipo 'Venta' */}
            {modalContent?.tipo === 'Venta' && venta && (
                <div>
                    <h3 className="table-title">Accounts Specifications</h3>
                    <div className="table-container-2">
                        <div className="table-section">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="info-title">Battle Tag</td>
                                        <td>{venta.battle_tag}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">DPS Rank</td>
                                        <td>{venta.dps_rank}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Tank Rank</td>
                                        <td>{venta.tank_rank}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Support Rank</td>
                                        <td>{venta.support_rank}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">OW1 Coins</td>
                                        <td>{venta.ow1_coins}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Paid Currency Coins</td>
                                        <td>{venta.paid_currency_coins}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Golden Gun Coins</td>
                                        <td>{venta.golden_gun_coins}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">OWL Tokens</td>
                                        <td>{venta.overwatch_league_tokens}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-section">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="info-title">Golden Guns</td>
                                        <td>{venta.golden_guns}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Battle Pass Level</td>
                                        <td>{venta.battle_pass_level}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Rare Skins</td>
                                        <td>{venta.rare_skins}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Level</td>
                                        <td>{venta.level}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Ranked Ready</td>
                                        <td>{venta.ranked_ready}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Account Type</td>
                                        <td>{venta.account_type}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Battle Tag Change</td>
                                        <td>{venta.battle_tag_change}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Fake ID</td>
                                        <td>{venta.fake_id}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            {/* Mostrar los detalles si es tipo 'Boost' */}
            {modalContent?.tipo === 'Boost' && boost && (
                <div>
                    <h3 className="boost-table-title">Boost Specifications</h3>
                    <div className="boost-table-container">
                        <div className="boost-table-section">
                            <p className="boost-description">{boost.descripcion_detallada}</p>  {/* Mostrar la descripción detallada del boost */}
                        </div>
                    </div>
                </div>

            )}

            {/* Botón para cerrar el modal */}
            <button onClick={closeModal} className="close-modal-btn2">Cerrar</button>
        </div>
    </div>
)}

            <Footer />
        </div>
    );
};

export default Carrito;
