import React from 'react';
import Footer from './footer'; // Importa el Footer
import Navbar from './navbar'; // Importa el Navbar
import '../styles/fortnite.css';

const Fortnite = () => {
    return (
        <div className="main-container">
            <Navbar />
            {/* Aquí puedes agregar el contenido principal de Rivals */}
            <div className='contenedor-fortnite'>
                <h1>Fortnite</h1>
                <p>Coming Soon</p>
            </div>

            <Footer />
        </div>
    );
};

export default Fortnite;
