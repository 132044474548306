// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children, tipoRequerido, permitirNoLogueados, excluirTipo1 }) {
    const userType = localStorage.getItem('userType');

    // Si no hay sesión y se permite el acceso a no logueados, mostrar el contenido
    if (!userType && permitirNoLogueados) {
        return children;
    }

    // Excluir acceso para usuarios de tipo 1
    if (userType === '1' && excluirTipo1) {
        return <Navigate to="/admin" replace />;
    }

    // Verificar el tipo de usuario requerido
    if (tipoRequerido && userType !== tipoRequerido) {
        return <Navigate to="/" replace />;
    }

    // Mostrar el contenido si el usuario cumple con los permisos
    return children;
}

export default ProtectedRoute;
