import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/cuentas.css';
import Navbar from './navbar';
import Footer from './footer';

const Cuentas = () => {
    const [ventas, setVentas] = useState([]);
    const navigate = useNavigate();

    // Función para obtener las ventas desde la API
    const fetchVentas = async () => {
        try {
            const response = await fetch('https://alienboost.onrender.com/ventas');
            if (!response.ok) {
                throw new Error('Error al obtener las ventas');
            }
            const data = await response.json();
            
            // Filtrar ventas que tengan estado 2 o 3
            const filteredVentas = data.filter(venta => venta.status === 2 || venta.status === 3);
            setVentas(filteredVentas);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Llama a fetchVentas al montar el componente
    useEffect(() => {
        fetchVentas();
    }, []);

    return (
        <div className="main-container">
            <Navbar />
            <div className="ventas-container">
                {ventas.map((venta) => (
                    <div 
                        key={venta.id} 
                        className="card" 
                        onClick={() => navigate(`/card/${venta.id}`)}
                    >
                        <img src={venta.image} alt={venta.game} className="card-image" />
                        <div className="card-info">
                            <h3>{venta.title}</h3>
                            <p>USD ${venta.price.toFixed(2)}</p>
                        </div>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default Cuentas;
